

import React from 'react';

export const networkInfo = {
    networkState: '4g', // offline, 3g, 4g
    polling: false, // true, false,
    warningColors: {
        '4g': { color: '#569356', timeout: 400 },
        '3g': { color: '#f9a41e' },
        '2g': { color: '#f9a41e' },
        'offline': { color: '#c93232' },
    }
}

export const NetworkDetectContext = React.createContext({
    ...networkInfo
});
