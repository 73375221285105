import gql from 'graphql-tag';
export default gql`
subscription tableOrderUpdated( $id: String!){
    tableOrderUpdated(id: $id){
      id
      orderId
      counterId
      tokenId
      siteId
      name
      mobile
      email
      date
      orderFrom
      subOrderFrom
      timestamp
      updatedAt
      grandTotal
      tableNo
      blockedTables
      tableOrderStatus
      primaryWaiter
      primaryWaiter
      paymentMode
      paymentState
      paymentStatus
      paidAmt
      balanceAmt
  
      orderList{
        subOrderId
        totalPrice
        createdAt
        services{
          serviceId
          serviceName
          serviceRate
          pmInventory
          purchaseItemRef
          qty
          isUrgent
          uId
          addons{
            serviceName
            serviceRate
             qty
           }
          attachedCat
          status
          instruction
          station
          kotId
          tax
          taxType
          inprocessAt
          preparedAt
          completedAt
        }
      }
    }
  }`
//    {
    //    "id": "b148b379-a018-44e3-ae54-644bac405262:::25"
    //  }
    
  