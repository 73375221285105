import gql from 'graphql-tag';

export default gql`
    query getSite{
        getSite{
            id
            domain
            fqdn
            siteType
            no_of_products
            no_of_photos
            custName
            companyName
            gstNo
            fromDate
            tillDate
            courierOption
            basicSiteSetting {
                curr
                siteOptions
                appVersion
            }

        }
    }
`